// All simple ISO 3166 country data
// including: 2-character, 3-character, full country name and numeric code.
// Sorted alphabetical by country name (special characters on bottom)
export const countryListAllData = [
  {
    code: "AF",
    code3: "AFG",
    name: "AFGHANISTAN",
    label: "AFGHANISTAN",
    number: "004",
    key: 0,
  },
  {
    code: "AL",
    code3: "ALB",
    name: "ALBANIA",
    label: "ALBANIA",
    number: "008",
    key: 1,
  },
  {
    code: "DZ",
    code3: "DZA",
    name: "ALGERIA",
    label: "ALGERIA",
    number: "012",
    key: 2,
  },
  {
    code: "AD",
    code3: "AND",
    name: "ANDORRA",
    label: "ANDORRA",
    number: "020",
    key: 4,
  },
  {
    code: "AO",
    code3: "AGO",
    name: "ANGOLA",
    label: "ANGOLA",
    number: "024",
    key: 5,
  },
  {
    code: "AG",
    code3: "ATG",
    name: "ANTIGUA AND BARBUDA",
    label: "ANTIGUA AND BARBUDA",
    number: "028",
  },
  {
    code: "AR",
    code3: "ARG",
    name: "ARGENTINA",
    label: "ARGENTINA",
    number: "032",
    key: 8,
  },
  {
    code: "AM",
    code3: "ARM",
    name: "ARMENIA",
    label: "ARMENIA",
    number: "051",
    key: 9,
  },
  {
    code: "AU",
    code3: "AUS",
    name: "AUSTRALIA",
    label: "AUSTRALIA",
    number: "036",
    key: 11,
  },
  {
    code: "AT",
    code3: "AUT",
    name: "AUSTRIA",
    label: "AUSTRIA",
    number: "040",
    key: 12,
  },
  {
    code: "AZ",
    code3: "AZE",
    name: "AZERBAIJAN",
    label: "AZERBAIJAN",
    number: "031",
    key: 13,
  },
  {
    code: "BH",
    code3: "BHR",
    name: "BAHRAIN",
    label: "BAHRAIN",
    number: "048",
    key: 14,
  },
  {
    code: "BD",
    code3: "BGD",
    name: "BANGLADESH",
    label: "BANGLADESH",
    number: "050",
    key: 14,
  },
  {
    code: "BB",
    code3: "BRB",
    name: "BARBADOS",
    label: "BARBADOS",
    number: "052",
  },
  {
    code: "BY",
    code3: "BLR",
    name: "BELARUS",
    label: "BELARUS",
    number: "112",
  },
  {
    code: "BE",
    code3: "BEL",
    name: "BELGIUM",
    label: "BELGIUM",
    number: "056",
  },
  { code: "BZ", code3: "BLZ", name: "BELIZE", label: "BELIZE", number: "084" },
  { code: "BJ", code3: "BEN", name: "BENIN", label: "BENIN", number: "204" },

  { code: "BT", code3: "BTN", name: "BHUTAN", label: "BHUTAN", number: "064" },
  {
    code: "BO",
    code3: "BOL",
    name: "BOLIVIA (PLURINATIONAL STATE OF)",
    label: "BOLIVIA (PLURINATIONAL STATE OF)",
    number: "068",
  },
  {
    code: "BA",
    code3: "BIH",
    name: "BOSNIA AND HERZEGOVINA",
    label: "Bosnia",
    number: "070",
  },
  {
    code: "BW",
    code3: "BWA",
    name: "BOTSWANA",
    label: "BOTSWANA",
    number: "072",
  },

  { code: "BR", code3: "BRA", name: "BRAZIL", label: "BRAZIL", number: "076" },

  {
    code: "BN",
    code3: "BRN",
    name: "BRUNEI DARUSSALAM",
    label: "BRUNEI DARUSSALAM",
    number: "096",
  },
  {
    code: "BG",
    code3: "BGR",
    name: "BULGARIA",
    label: "BULGARIA",
    number: "100",
  },
  {
    code: "BF",
    code3: "BFA",
    name: "BURKINA FASO",
    label: "BURKINA FASO",
    number: "854",
  },
  {
    code: "BI",
    code3: "BDI",
    name: "BURUNDI",
    label: "BURUNDI",
    number: "108",
  },
  {
    code: "CV",
    code3: "CPV",
    name: "CABO VERDE",
    label: "CABO VERDE",
    number: "132",
  },
  {
    code: "KH",
    code3: "KHM",
    name: "CAMBODIA",
    label: "CAMBODIA",
    number: "116",
  },
  {
    code: "CM",
    code3: "CMR",
    name: "CAMEROON",
    label: "CAMEROON",
    number: "120",
  },
  { code: "CA", code3: "CAN", name: "CANADA", label: "CANADA", number: "124" },
  {
    code: "CF",
    code3: "CAF",
    name: "CENTRAL AFRICAN REPUBLIC (THE)",
    label: "CENTRAL AFRICAN REPUBLIC (THE)",
    number: "140",
  },
  { code: "TD", code3: "TCD", name: "CHAD", label: "CHAD", number: "148" },
  // {
  //   code: "",
  //   code3: "",
  //   name: "Channel Islands",
  //   label: "Channel Islands",
  //   number: "148",
  // },
  { code: "CL", code3: "CHL", name: "CHILE", label: "CHILE", number: "152" },
  { code: "CN", code3: "CHN", name: "CHINA", label: "CHINA", number: "156" },

  {
    code: "CO",
    code3: "COL",
    name: "COLOMBIA",
    label: "COLOMBIA",
    number: "170",
  },
  {
    code: "KM",
    code3: "COM",
    name: "COMOROS (THE)",
    label: "COMOROS (THE)",
    number: "174",
  },

  {
    code: "CG",
    code3: "COG",
    name: "CONGO (THE)",
    label: "CONGO (THE)",
    number: "178",
  },
  {
    code: "CR",
    code3: "CRI",
    name: "COSTA RICA",
    label: "COSTA RICA",
    number: "188",
  },
  {
    code: "CI",
    code3: "CIV",
    name: "CÔTE D'IVOIRE",
    label: "CÔTE D'IVOIRE",
    number: "384",
  },
  {
    code: "HR",
    code3: "HRV",
    name: "CROATIA",
    label: "CROATIA",
    number: "191",
  },
  { code: "CU", code3: "CUB", name: "CUBA", label: "CUBA", number: "192" },
  { code: "CY", code3: "CYP", name: "CYPRUS", label: "CYPRUS", number: "196" },
  {
    code: "CZ",
    code3: "CZE",
    name: "CZECH REPUBLIC (THE)",
    label: "CZECH REPUBLIC (THE)",
    number: "203",
  },

  {
    code: "DK",
    code3: "DNK",
    name: "DENMARK",
    label: "DENMARK",
    number: "208",
  },
  {
    code: "DJ",
    code3: "DJI",
    name: "DJIBOUTI",
    label: "DJIBOUTI",
    number: "262",
  },
  {
    code: "DM",
    code3: "DMA",
    name: "DOMINICA",
    label: "DOMINICA",
    number: "212",
  },
  {
    code: "DO",
    code3: "DOM",
    name: "DOMINICAN REPUBLIC (THE)",
    label: "DOMINICAN REPUBLIC (THE)",
    number: "214",
  },
  {
    code: "DO",
    code3: "DOM",
    name: "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
    label: "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
    number: "2144",
  },
  {
    code: "EC",
    code3: "ECU",
    name: "ECUADOR",
    label: "ECUADOR",
    number: "218",
  },
  { code: "EG", code3: "EGY", name: "EGYPT", label: "EGYPT", number: "818" },
  {
    code: "SV",
    code3: "SLV",
    name: "EL SALVADOR",
    label: "EL SALVADOR",
    number: "222",
  },
  {
    code: "GQ",
    code3: "GNQ",
    name: "EQUATORIAL GUINEA",
    label: "EQUATORIAL GUINEA",
    number: "226",
  },
  {
    code: "ER",
    code3: "ERI",
    name: "ERITREA",
    label: "ERITREA",
    number: "232",
  },
  {
    code: "EE",
    code3: "EST",
    name: "ESTONIA",
    label: "ESTONIA",
    number: "233",
  },
  // {
  //   code: "SZ",
  //   code3: "SWZ",
  //   name: "Eswatini",
  //   label: "Eswatini",
  //   number: "748",
  // },
  {
    code: "ET",
    code3: "ETH",
    name: "ETHIOPIA",
    label: "ETHIOPIA",
    number: "231",
  },
  {
    code: "FI",
    code3: "FIN",
    name: "FINLAND",
    label: "FINLAND",
    number: "246",
  },
  { code: "FR", code3: "FRA", name: "FRANCE", label: "FRANCE", number: "250" },
  {
    code: "GF",
    code3: "GUF",
    name: "FRENCH GUIANA",
    label: "FRENCH GUIANA",
    number: "254",
  },
  { code: "GA", code3: "GAB", name: "GABON", label: "GABON", number: "266" },
  {
    code: "GM",
    code3: "GMB",
    name: "GAMBIA (THE)",
    label: "GAMBIA (THE)",
    number: "270",
  },
  {
    code: "GE",
    code3: "GEO",
    name: "GEORGIA",
    label: "GEORGIA",
    number: "268",
  },
  {
    code: "DE",
    code3: "DEU",
    name: "GERMANY",
    label: "GERMANY",
    number: "276",
  },
  { code: "GH", code3: "GHA", name: "GHANA", label: "GHANA", number: "288" },
  {
    code: "GI",
    code3: "GIB",
    name: "GIBRALTAR",
    label: "GIBRALTAR",
    number: "292",
  },
  { code: "GR", code3: "GRC", name: "GREECE", label: "GREECE", number: "300" },
  {
    code: "GD",
    code3: "GRD",
    name: "GRENADA",
    label: "GRENADA",
    number: "308",
  },
  {
    code: "GT",
    code3: "GTM",
    name: "GUATEMALA",
    label: "GUATEMALA",
    number: "320",
  },

  { code: "GN", code3: "GIN", name: "GUINEA", label: "GUINEA", number: "324" },
  {
    code: "GW",
    code3: "GNB",
    name: "GUINEA-BISSAU",
    label: "GUINEA-BISSAU",
    number: "624",
  },
  { code: "GY", code3: "GUY", name: "GUYANA", label: "GUYANA", number: "328" },
  { code: "HT", code3: "HTI", name: "HAITI", label: "HAITI", number: "332" },

  {
    code: "VA",
    code3: "VAT",
    name: "HOLY SEE (THE)",
    label: "HOLY SEE (THE)",
    number: "336",
  },
  {
    code: "HN",
    code3: "HND",
    name: "HONDURAS",
    label: "HONDURAS",
    number: "340",
  },
  {
    code: "HK",
    code3: "HKG",
    name: "HONG KONG",
    label: "HONG KONG",
    number: "344",
  },
  {
    code: "HU",
    code3: "HUN",
    name: "HUNGARY",
    label: "HUNGARY",
    number: "348",
  },
  {
    code: "IS",
    code3: "ISL",
    name: "ICELAND",
    label: "ICELAND",
    number: "352",
  },
  { code: "IN", code3: "IND", name: "INDIA", label: "INDIA", number: "356" },
  {
    code: "ID",
    code3: "IDN",
    name: "INDONESIA",
    label: "INDONESIA",
    number: "360",
  },
  {
    code: "IR",
    code3: "IRN",
    name: "IRAN (ISLAMIC REPUBLIC OF)",
    label: "IRAN (ISLAMIC REPUBLIC OF)",
    number: "364",
  },
  { code: "IQ", code3: "IRQ", name: "IRAQ", label: "IRAQ", number: "368" },
  {
    code: "IE",
    code3: "IRL",
    name: "IRELAND",
    label: "IRELAND",
    number: "372",
  },
  {
    code: "IM",
    code3: "IMN",
    name: "ISLE OF MAN",
    label: "ISLE OF MAN",
    number: "833",
  },
  { code: "IL", code3: "ISR", name: "ISRAEL", label: "ISRAEL", number: "376" },
  { code: "IT", code3: "ITA", name: "ITALY", label: "ITALY", number: "380" },
  {
    code: "JM",
    code3: "JAM",
    name: "JAMAICA",
    label: "JAMAICA",
    number: "388",
  },
  { code: "JP", code3: "JPN", name: "JAPAN", label: "JAPAN", number: "392" },
  { code: "JO", code3: "JOR", name: "JORDAN", label: "JORDAN", number: "400" },
  {
    code: "KZ",
    code3: "KAZ",
    name: "KAZAKHSTAN",
    label: "KAZAKHSTAN",
    number: "398",
  },
  { code: "KE", code3: "KEN", name: "KENYA", label: "KENYA", number: "404" },
  { code: "KW", code3: "KWT", name: "KUWAIT", label: "KUWAIT", number: "414" },
  {
    code: "KG",
    code3: "KGZ",
    name: "KYRGYZSTAN",
    label: "KYRGYZSTAN",
    number: "417",
  },
  {
    code: "LA",
    code3: "LAO",
    name: "LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)",
    label: "LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)",
    number: "418",
  },
  { code: "LV", code3: "LVA", name: "LATVIA", label: "LATVIA", number: "428" },
  {
    code: "LB",
    code3: "LBN",
    name: "LEBANON",
    label: "LEBANON",
    number: "422",
  },
  {
    code: "LS",
    code3: "LSO",
    name: "LESOTHO",
    label: "LESOTHO",
    number: "426",
  },
  {
    code: "LR",
    code3: "LBR",
    name: "LIBERIA",
    label: "LIBERIA",
    number: "430",
  },
  { code: "LY", code3: "LBY", name: "LIBYA", label: "LIBYA", number: "434" },
  {
    code: "LI",
    code3: "LIE",
    name: "LIECHTENSTEIN",
    label: "LIECHTENSTEIN",
    number: "438",
  },
  {
    code: "LT",
    code3: "LTU",
    name: "LITHUANIA",
    label: "LITHUANIA",
    number: "440",
  },
  {
    code: "LU",
    code3: "LUX",
    name: "LUXEMBOURG",
    label: "LUXEMBOURG",
    number: "442",
  },
  { code: "MO", code3: "MAC", name: "MACAO", label: "MACAO", number: "446" },
  {
    code: "MG",
    code3: "MDG",
    name: "MADAGASCAR",
    label: "MADAGASCAR",
    number: "450",
  },
  { code: "MW", code3: "MWI", name: "MALAWI", label: "MALAWI", number: "454" },
  {
    code: "MY",
    code3: "MYS",
    name: "MALAYSIA",
    label: "MALAYSIA",
    number: "458",
  },
  {
    code: "MV",
    code3: "MDV",
    name: "MALDIVES",
    label: "MALDIVES",
    number: "462",
  },
  { code: "ML", code3: "MLI", name: "MALI", label: "MALI", number: "466" },
  { code: "MT", code3: "MLT", name: "MALTA", label: "MALTA", number: "470" },

  {
    code: "MR",
    code3: "MRT",
    name: "MAURITANIA",
    label: "MAURITANIA",
    number: "478",
  },
  {
    code: "MU",
    code3: "MUS",
    name: "MAURITIUS",
    label: "MAURITIUS",
    number: "480",
  },
  {
    code: "YT",
    code3: "MYT",
    name: "MAYOTTE",
    label: "MAYOTTE",
    number: "175",
  },
  { code: "MX", code3: "MEX", name: "MEXICO", label: "MEXICO", number: "484" },

  {
    code: "MD",
    code3: "MDA",
    name: "MOLDOVA (THE REPUBLIC OF)",
    label: "MOLDOVA (THE REPUBLIC OF)",
    number: "498",
  },
  { code: "MC", code3: "MCO", name: "MONACO", label: "MONACO", number: "492" },
  {
    code: "MN",
    code3: "MNG",
    name: "MONGOLIA",
    label: "MONGOLIA",
    number: "496",
  },
  {
    code: "ME",
    code3: "MNE",
    name: "MONTENEGRO",
    label: "MONTENEGRO",
    number: "499",
  },
  {
    code: "MA",
    code3: "MAR",
    name: "MOROCCO",
    label: "MOROCCO",
    number: "504",
  },
  {
    code: "MZ",
    code3: "MOZ",
    name: "MOZAMBIQUE",
    label: "MOZAMBIQUE",
    number: "508",
  },
  {
    code: "MM",
    code3: "MMR",
    name: "MYANMAR",
    label: "MYANMAR",
    number: "104",
  },
  {
    code: "NA",
    code3: "NAM",
    name: "NAMIBIA",
    label: "NAMIBIA",
    number: "516",
  },
  { code: "NP", code3: "NPL", name: "NEPAL", label: "NEPAL", number: "524" },
  {
    code: "NL",
    code3: "NLD",
    name: "NETHERLANDS (THE)",
    label: "NETHERLANDS (THE)",
    number: "528",
  },
  // {
  //   code: "NZ",
  //   code3: "NZL",
  //   name: "New Zealand",
  //   label: "New Zealand",
  //   number: "554",
  // },
  {
    code: "NI",
    code3: "NIC",
    name: "NICARAGUA",
    label: "NICARAGUA",
    number: "558",
  },
  {
    code: "NE",
    code3: "NER",
    name: "NIGER (THE)",
    label: "NIGER (THE)",
    number: "562",
  },
  {
    code: "NG",
    code3: "NGA",
    name: "NIGERIA",
    label: "NIGERIA",
    number: "566",
  },
  {
    code: "NG",
    code3: "NGA",
    name: "KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)",
    label: "KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)",
    number: "566",
  },

  {
    code: "NU",
    code3: "NIU",
    name: "REPUBLIC OF NORTH MACEDONIA",
    label: "REPUBLIC OF NORTH MACEDONIA",
    number: "570",
  },

  { code: "NO", code3: "NOR", name: "NORWAY", label: "NORWAY", number: "578" },
  { code: "OM", code3: "OMN", name: "OMAN", label: "OMAN", number: "512" },
  {
    code: "PK",
    code3: "PAK",
    name: "PAKISTAN",
    label: "PAKISTAN",
    number: "586",
  },

  { code: "PA", code3: "PAN", name: "PANAMA", label: "PANAMA", number: "591" },

  {
    code: "PY",
    code3: "PRY",
    name: "PARAGUAY",
    label: "PARAGUAY",
    number: "600",
  },
  { code: "PE", code3: "PER", name: "PERU", label: "PERU", number: "604" },
  {
    code: "PH",
    code3: "PHL",
    name: "PHILIPPINES (THE)",
    label: "PHILIPPINES (THE)",
    number: "608",
  },

  { code: "PL", code3: "POL", name: "POLAND", label: "POLAND", number: "616" },
  {
    code: "PT",
    code3: "PRT",
    name: "PORTUGAL",
    label: "PORTUGAL",
    number: "620",
  },

  { code: "QA", code3: "QAT", name: "QATAR", label: "QATAR", number: "634" },

  {
    code: "RE",
    code3: "REU",
    name: "RÉUNION",
    label: "RÉUNION",
    number: "638",
  },

  {
    code: "RO",
    code3: "ROU",
    name: "ROMANIA",
    label: "ROMANIA",
    number: "642",
  },
  {
    code: "RU",
    code3: "RUS",
    name: "RUSSIAN FEDERATION (THE)",
    label: "RUSSIAN FEDERATION (THE)",
    number: "643",
  },
  { code: "RW", code3: "RWA", name: "RWANDA", label: "RWANDA", number: "646" },

  {
    code: "SH",
    code3: "SHN",
    name: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    label: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    number: "654",
  },
  {
    code: "KN",
    code3: "KNA",
    name: "SAINT KITTS AND NEVIS",
    label: "SAINT KITTS AND NEVIS",
    number: "659",
  },
  {
    code: "LC",
    code3: "LCA",
    name: "SAINT LUCIA",
    label: "SAINT LUCIA",
    number: "662",
  },

  {
    code: "VC",
    code3: "VCT",
    name: "SAINT VINCENT AND THE GRENADINES",
    label: "SAINT VINCENT AND THE GRENADINES",
    number: "670",
  },
  // { code: "WS", code3: "WSM", name: "Samoa", label: "Samoa", number: "882" },
  {
    code: "SM",
    code3: "SMR",
    name: "SAN MARINO",
    label: "SAN MARINO",
    number: "674",
  },
  {
    code: "ST",
    code3: "STP",
    name: "SAO TOME AND PRINCIPE",
    label: "SAO TOME AND PRINCIPE",
    number: "678",
  },
  {
    code: "SA",
    code3: "SAU",
    name: "SAUDI ARABIA",
    label: "SAUDI ARABIA",
    number: "682",
  },
  {
    code: "SN",
    code3: "SEN",
    name: "SENEGAL",
    label: "SENEGAL",
    number: "686",
  },
  { code: "RS", code3: "SRB", name: "SERBIA", label: "SERBIA", number: "688" },
  {
    code: "SC",
    code3: "SYC",
    name: "SEYCHELLES",
    label: "SEYCHELLES",
    number: "690",
  },
  {
    code: "SL",
    code3: "SLE",
    name: "SIERRA LEONE",
    label: "SIERRA LEONE",
    number: "694",
  },
  {
    code: "SG",
    code3: "SGP",
    name: "SINGAPORE",
    label: "SINGAPORE",
    number: "702",
  },

  {
    code: "SK",
    code3: "SVK",
    name: "SLOVAKIA",
    label: "SLOVAKIA",
    number: "703",
  },
  {
    code: "SI",
    code3: "SVN",
    name: "SLOVENIA",
    label: "SLOVENIA",
    number: "705",
  },
  {
    code: "SB",
    code3: "SLB",
    name: "SOLOMON ISLANDS",
    label: "SOLOMON ISLANDS",
    number: "090",
  },
  {
    code: "SO",
    code3: "SOM",
    name: "SOMALIA",
    label: "SOMALIA",
    number: "706",
  },
  {
    code: "ZA",
    code3: "ZAF",
    name: "SOUTH AFRICA",
    label: "SOUTH AFRICA",
    number: "710",
  },
  {
    code: "SA",
    code3: "ZAF",
    name: "KOREA (THE REPUBLIC OF)",
    label: "KOREA (THE REPUBLIC OF)",
    number: "710",
  },

  {
    code: "SS",
    code3: "SSD",
    name: "SOUTH SUDAN",
    label: "SOUTH SUDAN",
    number: "728",
  },
  { code: "ES", code3: "ESP", name: "SPAIN", label: "SPAIN", number: "724" },
  {
    code: "LK",
    code3: "LKA",
    name: "SRI LANKA",
    label: "SRI LANKA",
    number: "144",
  },
  {
    code: "SP",
    code3: "LKA",
    name: "PALESTINE, STATE OF",
    label: "PALESTINE, STATE OF",
    number: "144",
  },
  {
    code: "SD",
    code3: "SDN",
    name: "SUDAN (THE)",
    label: "SUDAN (THE)",
    number: "729",
  },
  {
    code: "SR",
    code3: "SUR",
    name: "SURINAME",
    label: "SURINAME",
    number: "740",
  },
  { code: "SE", code3: "SWE", name: "SWEDEN", label: "SWEDEN", number: "752" },
  {
    code: "CH",
    code3: "CHE",
    name: "SWITZERLAND",
    label: "SWITZERLAND",
    number: "756",
  },
  {
    code: "SY",
    code3: "SYR",
    name: "SYRIAN ARAB REPUBLIC",
    label: "SYRIAN ARAB REPUBLIC",
    number: "760",
  },
  { code: "TW", code3: "TWN", name: "TAIWAN (PROVINCE OF CHINA)", label: "TAIWAN (PROVINCE OF CHINA)", number: "158" },
  {
    code: "TJ",
    code3: "TJK",
    name: "TAJIKISTAN",
    label: "TAJIKISTAN",
    number: "762",
  },
  {
    code: "TZ",
    code3: "TZA",
    name: "TANZANIA, UNITED REPUBLIC OF",
    label: "TANZANIA, UNITED REPUBLIC OF",
    number: "834",
  },
  {
    code: "TH",
    code3: "THA",
    name: "THAILAND",
    label: "THAILAND",
    number: "764",
  },

  {
    code: "TK",
    code3: "TKL",
    name: "BAHAMAS (THE)",
    label: "BAHAMAS (THE)",
    number: "772",
  },
  {
    code: "TK",
    code3: "TKL",
    name: "TIMOR-LESTE",
    label: "TIMOR-LESTE",
    number: "772",
  },
  { code: "TO", code3: "TON", name: "TOGO", label: "TOGO", number: "776" },
  {
    code: "TO",
    code3: "TON",
    name: "TRINIDAD AND TOBAGO",
    label: "TRINIDAD AND TOBAGO",
    number: "776",
  },

  {
    code: "TN",
    code3: "TUN",
    name: "TUNISIA",
    label: "TUNISIA",
    number: "788",
  },
  { code: "TR", code3: "TUR", name: "TURKEY", label: "TURKEY", number: "792" },
  {
    code: "TM",
    code3: "TKM",
    name: "TURKMENISTAN",
    label: "TURKMENISTAN",
    number: "795",
  },

  { code: "UG", code3: "UGA", name: "UGANDA", label: "UGANDA", number: "800" },
  {
    code: "UA",
    code3: "UKR",
    name: "UKRAINE",
    label: "UKRAINE",
    number: "804",
  },
  {
    code: "AE",
    code3: "ARE",
    name: "UNITED ARAB EMIRATES (THE)",
    label: "UNITED ARAB EMIRATES (THE)",
    number: "784",
  },
  {
    code: "GB",
    code3: "GBR",
    name: "UNITED KINGDOM",
    label: "UNITED KINGDOM",
    number: "826",
  },

  {
    code: "US",
    code3: "USA",
    name: "UNITED STATES OF AMERICA (THE)",
    label: "UNITED STATES OF AMERICA (THE)",
    number: "840",
  },
  {
    code: "UY",
    code3: "URY",
    name: "URUGUAY",
    label: "URUGUAY",
    number: "858",
  },
  {
    code: "UZ",
    code3: "UZB",
    name: "UZBEKISTAN",
    label: "UZBEKISTAN",
    number: "860",
  },
  {
    code: "VE",
    code3: "VEN",
    name: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    label: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    number: "862",
  },
  {
    code: "VN",
    code3: "VNM",
    name: "VIET NAM",
    label: "VIET NAM",
    number: "704",
  },

  {
    code: "EH",
    code3: "ESH",
    name: "WESTERN SAHARA",
    label: "WESTERN SAHARA",
    number: "732",
  },
  { code: "YE", code3: "YEM", name: "YEMEN", label: "YEMEN", number: "887" },
  { code: "ZM", code3: "ZMB", name: "ZAMBIA", label: "ZAMBIA", number: "894" },
  {
    code: "ZW",
    code3: "ZWE",
    name: "ZIMBABWE",
    label: "ZIMBABWE",
    number: "716",
  },
];
